import request from '@/utils/request'

export function getSysDictData(typeName) {
  return request({
    url: 'system/dict/dictDatas/' + typeName,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getCostSubjectList(bizType) {
  return request({
    url: 'reimburse/costSubjects/costSubject/' + bizType,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getOwnerList() {
  return request({
    url: 'reimburse/reiUserAuth/getReiuseridByCurrentUser',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
// 获取省份列表
export function getProvinceList() {
  return request({
    url: 'system/location/locationList',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getAllownceStandard(data) {
  return request({
    url: 'reimburse/allownceStandard/standardInfo',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

export function getTravelOtherList() {
  return request({
    url: 'reimburse/travelOther/travelOtherList',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getProjectList(data) {
  return request({
    url: 'reimburse/reiProject/getProjectsListByCostSubjectsAndUserId',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

// 差旅报销保存报销单信息
export function saveReiForm(data) {
  // console.log(data.ownerId)
  // console.log(data.applyDate)
  return request({
    url: 'reimburse/travelReimburse/travelReiForm',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

// 获取差旅报销费用科目列表
export function getTravelCostSubjects() {
  return request({
    url: 'reimburse/costSubjects/travelCostSubjects',
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

export function getReiFormProSubjectsList(data) {
  return request({
    url: 'reimburse/reiProject/getSubjectAndProDetailList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

export function queryReiFormById(id) {
  return request({
    url: 'reimburse/reiForm/getById/' + id,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

// 根据任务ID获取审批流程
export function getDiagram(taskId) {
  return request({
    url: 'reimburse/reiForm/getDiagramByTaskId/' + taskId,
    method: 'get'
  })
}

// 根据报销单ID获取报销单日志
export function getBizLogs(pageCurrent, pageSize, formId) {
  return request({
    url: 'reimburese/reiBizLog/pageBizLogList',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'pageCurrent': pageCurrent,
      'pageSize': pageSize,
      'bizId': formId
    }
  })
}

// 审批通过
export function reviewForm(reiTaskId, auditOpinion, operationName, formId, signImgUrl) {
  // console.log('请求数据是' + reiTaskId + auditOpinion + operationName)
  return request({
    url: 'reimburse/reiForm/reiFormReview',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'reiTaskId': reiTaskId,
      'auditOpinion': auditOpinion,
      'operationName': operationName,
      'formId': formId,
      'signImgUrl': signImgUrl
    }
  })
}

// 审批退回
export function backForm(data) {
  return request({
    url: 'reimburse/reiForm/toDraftReiForm',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data
  })
}

// 根据报销单ID获取报销单任务表
export function getTasks(pageCurrent, pageSize, formId) {
  return request({
    url: 'reimburse/reiTask/getReiTaskListByFormId',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    transformRequest: [function(data) {
      data = JSON.stringify(data)
      return data
    }],
    data: {
      'pageCurrent': pageCurrent,
      'pageSize': pageSize,
      'reiFormId': formId
    }
  })
}

// 获取当前环节名称
export function getCurrentStepName(formId) {
  return request({
    url: 'reimburse/reiTask/getTaskByFormIdBelongToCurrentUser/' + formId,
    method: 'get',
    headers: {
      'Content-Type': 'application/json'
    }
  })
}
// 获取当前环节名称
export function getLoanInfo(reqData) {
  // console.log(reqData)
  return request({
    url: 'reimburese/reiLoan/reiLoan/',
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    data: reqData
  })
}
