<!-- 报销单基本信息 -->
<template>
  <div id="reimdetails">
    <!-- 头部地理位置和搜索框 -->
    <div class="head">
      <!-- 顶部NavBar 开始 -->
      <van-nav-bar title="报销单基本信息" left-text="" left-arrow class="usercenter" @click-left="onClickLeft" />
    </div>
    <!-- <van-loading
					size="24px"
					type="spinner"
					color="#1989fa"
					style="text-align:center"
					v-if="loading"
		>加载中...</van-loading> -->
    <!-- <van-button type="info" size="small" @click="getScrollTop" style="float:right" >滚动到底部</van-button> -->
    <!-- 报销单详情概括 -->
    <div id="detailview">
      <div>
        <van-collapse id="collapses" v-model="activeNames" :border="false" @change="activeName">
          <van-collapse-item :border="false" class="person" :name="namevalue" :model="list" style="margin-bottom:0;">
            <div slot="title">
              <van-row class="head">
                <van-col span="4">
                  <img src="../../assets/images/reimtotal.svg" width="30">
                </van-col>
                <van-col span="20" style="line-height: 32px; ">
                  易缴费
                </van-col>
              </van-row>
              <p style="font-size:14px;color: #969799;text-align: right;position: absolute;right: 35px;float:left;">
                {{ Reim }}
              </p>
            </div>
            <van-divider style="margin: 2px 0 8px 0;" />
            <div class="body">
              <van-row style="margin-top:10px;line-height: 25px;">
                <van-col span="5">报销单号:</van-col>
                <van-col span="19" style="color:#333333">{{ list.formNo }}</van-col>
                <van-col span="5">申请日期:</van-col>
                <van-col span="19" style="color:#333333">{{ list.applyDate }}</van-col>
                <van-col span="5">报销类型:</van-col>
                <van-col span="19" style="color:#333333">{{ list.type }}</van-col>
                <van-col span="5">摘要信息:</van-col>
                <van-col span="19" style="color:#333333">{{ list.summary }}</van-col>
                <van-col span="5">报销人员:</van-col>
                <van-col span="19" style="color:#333333">{{ list.ownerIdserial }}({{ list.ownerName }})</van-col>
              </van-row>
            </div>
            <!-- </div> -->
          </van-collapse-item>
        </van-collapse>

        <img :src="imagesrc[3]" style="width: 100%;margin: 0;padding: 0;font-size: 0;display: block;">
        <!-- 报销明细 -->
        <img :src="imagesrc[5]" style="width: 100%;margin: 0;padding: 0;font-size: 0;display: block;">
        <van-collapse id="collapse" v-model="Reimbursementdetails" :border="false" @change="Reimbursementdetail">
          <van-collapse-item :border="false" :value="Reimdetail" name="1" class="persondetail" style="margin-top:0;margin-bottom:0;">
            <div slot="title">
              <div style="border-left: 4px #1989fa solid;padding-left: 5px;font-size: 16px;font-weight: 500;line-height: 16px;">报销明细</div>
            </div>
            <div class="bodydetail">
              <div v-for="item in reiFormProSubjectsList" :key="item.id">
                <van-divider />
                <van-row style="margin-top:10px;line-height: 25px;">
                  <van-col span="5">费用科目:</van-col>
                  <van-col span="19" style="color:#333333">{{ item.costSubjectsName }}</van-col>
                  <van-col span="5">报销项目:</van-col>
                  <van-col span="19" style="color:#333333">{{ item.projectName }}</van-col>
                  <van-col span="5">摘要信息:</van-col>
                  <van-col span="19" style="color:#333333">{{ item.limit==''||item.limit==undefined?'无':item.limit }}</van-col>
                  <van-col span="5">报销金额:</van-col>
                  <van-col span="19" style="color:#333333">{{ item.reiAmtStr }}</van-col>
                </van-row>
              </div>
              <div style="color:#1989fa">
                <van-divider />
                <div>总计 :<span style="float:right;font-weight:700;margin-right:15px">{{ totallist }}元</span></div>
                <div>(大写):<span style="float:right;font-weight:700;margin-right:15px">{{ totallistchinese }}</span></div>
              </div>
              <div id="heig">
                <van-divider />
                <van-row v-for="(item , index) in taskList" :key="index">
                  <van-col span="12" style="line-height:30px">{{ item.stepModelName }}签字:</van-col>
                  <van-col span="12">
                    <img v-if="item.signature!=null" style="height: 30px;width: 80px;" alt="" :src="item.signature">
                  </van-col>
                </van-row>
              </div>
            </div>
            <!-- </div> -->
          </van-collapse-item>
        </van-collapse>
        <img :src="imagesrc[3]" style="width: 100%;margin: 0;padding: 0;font-size: 0;display: block;">
        <!-- 报销单支付信息 -->
        <img :src="imagesrc[5]" style="width: 100%;margin: 0;padding: 0;font-size: 0;display: block;">
        <van-collapse id="collapse" v-model="Reimbursementpayment" :border="false" @change="Reimbursementpaymen">
          <van-collapse-item :border="false" :value="Reimpay" name="1" class="persondetail" style="margin-top:0;margin-bottom:0;">
            <div slot="title">
              <div style="border-left: 4px #1989fa solid;padding-left: 5px;font-size: 16px;font-weight: 500;line-height: 16px;">报销单支付信息</div>
            </div>
            <div class="bodydetail">
              <div v-for="item in dynamicValidateForm.domains" :key="item.id">
                <van-divider />
                <van-row style="margin-top:10px;line-height: 25px;">
                  <van-col span="5">支付方式:</van-col>
                  <van-col span="19" style="color:#333333">{{ item.payway }}</van-col>
                  <van-col span="5">报销金额:</van-col>
                  <van-col span="19" style="color:#333333">{{ item.payAmtStr }}</van-col>
                  <van-col span="5" style="padding-left: 25px;">附言:</van-col>
                  <van-col span="19" style="color:#333333">{{ item.remark == ''? "无" : item.remark == null? "无" : item.remark }}</van-col>
                  <van-col span="5">详情说明:</van-col>
                  <van-col span="19" style="color:#333333">{{ item.printDetail }}</van-col>
                </van-row>
              </div>
              <van-divider />
              <div class="warnrules">
                <img :src="imagesrc[2]" style="width: 15px;float: left;"> 注意:实际报销金额以财务部门核定金额为准,报销标准详见财务部门相关制度规定。
              </div>
            </div>
          </van-collapse-item>
        </van-collapse>
        <!-- </div> -->
        <img :src="imagesrc[3]" style="width: 100%;margin: 0;padding: 0;font-size: 0;display: block;">
        <!-- 报销已审批环节 -->
        <div v-for="(item , index) in taskList" :key="index">
          <div v-if="item.finishtime==null?false:true">
            <img :src="imagesrc[5]" style="width: 100%;margin: 0;padding: 0;font-size: 0;display: block;">
            <van-collapse id="collapse" v-model="Process" :border="false" @change="Proces(index)">
              <van-collapse-item :border="false" :value="item.ReimProces" :name="index" class="personpeople" style="margin-top:0;margin-bottom:0;">
                <div slot="title">
                  <div style="border-left: 4px #1989fa solid;padding-left: 5px;font-size: 16px;font-weight: 500;line-height: 16px;">{{ item.stepModelName }}</div>
                </div>
                <div class="bodypeople">
                  <div>
                    <van-divider />
                    <van-row style="margin-top:10px;line-height: 25px;">
                      <van-col span="5">审批意见:</van-col>
                      <van-col span="19" style="color:#333333">{{ item.opinion==''||item.opinion==undefined?'无':item.opinion }}</van-col>
                      <van-col span="5">审批人员:</van-col>
                      <van-col span="19" style="color:#333333;">{{ item.opName }}</van-col>
                      <van-col span="5">审批时间:</van-col>
                      <van-col span="19" style="color:#333333">{{ item.finishtime }}</van-col>
                    </van-row>
                  </div>
                  <div>
                    <van-divider />
                    <van-row>
                      <van-col span="5">电子签名:</van-col>
                      <van-col span="19">
                        <img v-if="item.signature!=null" style="height: 30px;width: 80px;" alt="" :src="item.signature">
                      </van-col>
                    </van-row>
                  </div>
                </div>
                <!-- </div> -->
              </van-collapse-item>
            </van-collapse>
            <img v-if="index==taskList.length-1 && param.sector==false ? false:true" :src="imagesrc[3]" style="width: 100%;margin: 0;padding: 0;font-size: 0;display: block;">
            <img v-if="index==taskList.length-1 && param.sector==false ? true:false" :src="imagesrc[4]" style="width: 100%;margin: 0;padding: 0;font-size: 0;display: block;">
          </div>
        </div>
        <!-- 审批 -->
        <img v-if="param.sector" :src="imagesrc[5]" style="width: 100%;margin: 0;padding: 0;font-size: 0;display: block;">
        <div v-if="param.sector" class="personindex" style="margin-top:0;" :model="auditData">
          <div class="headindex">
            <div class="linestyle">
              请审批
            </div>
          </div>
          <div class="bodyindex">
            <div>
              <van-divider />
              <van-row style="margin-top:25px;margin-bottom:20px;">
                <van-col span="12" style="text-align:center;border-right: 1px #d8e6f7 solid;">
                  <van-col span="10" style="text-align:center;">
                    <img :src="imagesrc[0]" style="width: 50px;float: right;">
                  </van-col>
                  <van-col span="14" style="text-align:center;">
                    <p style="font-size:14px;color:#999999;text-align:left">当前环节</p>
                    <p style="font-size:12px;color:#ffc036;text-align:left">{{ currentStepName }}</p>
                  </van-col>
                </van-col>
                <van-col span="12" style="text-align:center;">
                  <van-col span="10" style="text-align:center;">
                    <img :src="imagesrc[1]" style="width: 50px;float:right">
                  </van-col>
                  <van-col span="14" style="text-align:center;">
                    <p style="font-size:14px;color:#999999;text-align: left;margin-left: 5px;">审批人员</p>
                    <p style="font-size:12px;color:#52cf81;text-align: left;margin-left: 5px;">{{ auditData.opUserName }}</p>
                  </van-col>
                </van-col>
                <van-col span="24" style="margin-top:15px;">审批意见:</van-col>
                <van-col id="textareaop" span="24" style="color:#333333">
                  <van-field v-model="param.opinion" rows="6" autosize placeholder="请输入审批意见(最多为100字符)" type="textarea" show-word-limit style="color: #1890ff !important;font-size:16px" />
                </van-col>
              </van-row>
              <van-row id="quickname" style="margin-top:15px;">
                <van-col span="6" style="color:#666666;line-height: 35px;">审批人签名:</van-col>
                <van-col span="12" style="text-align: center;"><img v-if="param.signImgUrl!= undefined" style="height: 35px;width: 120px;" alt="" :src="param.signImgUrl"></van-col>
                <van-col span="6" style="text-align: center;line-height: 35px;">
                  <van-button style="border-radius: 30px;background-color:#eff6ff;color:#1890ff;border:none" type="info" size="small" @click="quickname">去签名</van-button>
                </van-col>
              </van-row>
            </div>
            <div>
              <van-divider />
              <van-row id="color">
                <van-col span="24" style="text-align: center;margin: 15px 0;">
                  <van-button type="danger" icon="replay" size="small" class="button" @click="backForm">审批退回</van-button>
                  <van-button type="info" icon="passed" size="small" class="buttonsub" style="margin-left:15px" @click="reviewForm">审批提交</van-button>
                </van-col>
              </van-row>
            </div>
          </div>
        </div>
      </div>
      <!-- 回到顶部按钮 -->
      <v-top />
    </div>
  </div>
</template>

<script>
import { Dialog } from 'vant'
import moment from 'moment/moment'
import { queryReiFormById, getTasks, getCurrentStepName, backForm, reviewForm } from '@/api/reimdetails'
export default {
  name: 'Project',
  components: {
    [Dialog.Component.name]: Dialog.Component
    // SignatureView
  },
  data() {
    return {
      loading: false,
      showvacuous: false,
      scrollTop: 0,
      Reim: '收起全部',
      Reimdetail: '收起',
      ReimProces: '收起',
      Reimpay: '收起',
      activeNames: ['1'],
      Reimbursementdetails: ['1'],
      Reimbursementpayment: ['1'],
      Process: [],
      namevalue: '1',
      dynamicValidateForm: {
        domains: []
      },
      feeSubject: {
        tableDataDetail: []
      },
      project: {
        projectSelectListTemp: []
      },
      param: {},
      message: '',
      list: [],
      reiFormProSubjectsList: [],
      totallist: 0.00,
      totallistchinese: '',
      page: '1',
      limit: '10',
      taskList: [],
      auditData: {
        // 审批意见
        auditOpinion: '',
        // 任务ID
        reiTaskId: '',
        // 操作名称
        operationName: '',
        scrollTop: ''
      },
      auditOpinion: '',
      show: false,
      currentStepName: '',
      imagesrc: [
        require('../../assets/images/Currentsession.svg'),
        require('../../assets/images/textuer.svg'),
        require('../../assets/images/note.svg'),
        require('../../assets/images/blue_bottom.png'),
        require('../../assets/images/white_bottom.png'),
        require('../../assets/images/blue_top.png')
      ],
      signImgUrl: ''
    }
  },
  beforeCreate() {
    document.querySelector('body').setAttribute('style', 'background: #EFF6FF')
  },
  created() {
  },
  mounted() {
    this.param.bizType = this.$route.query.bizType
    this.param.id = this.$route.query.id
    this.param.sector = this.$route.query.sector
    this.param.signImgUrl = this.$route.query.signImgUrl
    this.param.name = this.$route.query.name
    this.param.opinion = this.$route.query.opinion
    this.signImgUrl = this.$route.query.signImgUrl
    this.getList()
    if (this.param.signImgUrl !== '' && this.param.signImgUrl !== undefined) {
      this.getScrollTop()
    }
  },
  methods: {
    getScrollTop() {
      setTimeout(function() {
        var t = document.getElementById('reimdetails').clientHeight
        // alert('max height ==' + t)
        window.scroll({ top: t, left: 0, behavior: 'smooth' })
      }, 500)
    },
    activeName(event) {
      if (event === '') {
        this.Reim = '展开全部'
        this.Reimdetail = '展开'
        this.Reimpay = '展开'
        // this.ReimProces="展开"
        for (let i = 0; i < this.taskList.length; i++) {
          this.taskList[i].ReimProces = '展开'
        }
        this.Reimbursementdetails = []
        this.Reimbursementpayment = []
        this.Process = []
      } else {
        this.Reim = '收起全部'
        this.Reimdetail = '收起'
        this.Reimpay = '收起'
        for (let i = 0; i < this.taskList.length; i++) {
          this.taskList[i].ReimProces = '收起'
        }
        this.Reimbursementdetails = ['1']
        this.Reimbursementpayment = ['1']
        const processlength = []
        for (let i = 0; i < this.taskList.length; i++) {
          processlength.push(i)
        }
        this.Process = processlength
      }
    },
    Reimbursementdetail(event) {
      if (event === '') {
        this.Reimdetail = '展开'
      } else {
        this.Reimdetail = '收起'
      }
    },
    Reimbursementpaymen(event) {
      if (event === '') {
        this.Reimpay = '展开'
      } else {
        this.Reimpay = '收起'
      }
    },
    Proces(event) {
      // console.log(event)
      // console.log(this.taskList[event].ReimProces)
      if (this.taskList[event].ReimProces === '展开') {
        this.taskList[event].ReimProces = '收起'
      } else {
        this.taskList[event].ReimProces = '展开'
      }
      // console.log(this.taskList[event].ReimProces)
    },
    onClickLeft() {
      if (this.param.name === 'todo') {
        this.$router.push({
          path: '/todo'
        })
      } else if (this.param.name === 'progress') {
        this.$router.push({
          path: '/progressTracking'
        })
      }
    },
    quickname() {
      this.$router.push({
        path: '/signatures',
        query: {
          auditOpinion: this.param.opinion,
          highcroll: this.scrollTop
        }
      })
    },

    getList() {
      // this.getScrollTop()
      // alert("我刷新了")
      this.loading = true
      this.showvacuous = false
      if (this.param.bizType === 1 || this.param.bizType === 2 || this.param.bizType === 3) {
        // setTimeout(() => {
        queryReiFormById(this.param.id).then(response => {
          this.list = response.data
          this.loading = false
          this.showvacuous = true
          // console.log(this.list)
          this.param.bizType === 1 ? this.list.type = '日常报销' : this.param.bizType === 2 ? this.list.type = '差旅报销' : this.list.type = '借款'
          for (let i = 0; i < response.data.reiFormProSubjectsList.length; i++) {
            if (response.data.reiFormProSubjectsList[i].projectName !== '未分摊金额') {
              this.reiFormProSubjectsList.push(response.data.reiFormProSubjectsList[i])
              this.totallist = this.list.reiAmtStr
              this.totallistchinese = this.common.changeBigmoney(this.totallist)
            }
          }
          response.data.reiFormSubjectsList.forEach(element => {
            const obj = {}
            obj.costTypeName = element.costTypeName
            obj.costType = element.element
            obj.fullName = element.costSubjectsName
            obj.summary = element.summary
            obj.reiAmt = element.reiAmt
            obj.amount = element.reiAmtStr
            obj.description = element.description
            obj.demand = element.demand
            // 将费用科目id 赋值 因为保存并下一步时候读取的是costSubjectsId
            obj.subjectId = element.costSubjectsId
            this.feeSubject.tableDataDetail.push(obj)
          })
          // 获取报销单项目信息
          this.project.projectSelectListTemp = []
          response.data.reiFormProList.forEach(element => {
            const obj = {}
            obj.code = element.proCode
            obj.name = element.proName
            obj.projectLeaderName = element.projectLeaderName
            obj.balance = element.balance
            obj.ctlType = element.ctlType
            obj.proId = element.proId
            obj.id = element.proId
            this.project.projectSelectListTemp.push(obj)
          })
          // 获取分录信息
          this.tableDataProject = []
          response.data.reiFormProSubjectsList.forEach(element => {
            const obj = {}
            obj.costSubName = element.costSubjectsName
            obj.costSubCode = element.costSubjectsCode
            obj.subjectsId = element.subjectsId
            obj.costSubId = element.subjectsId
            obj.projectCode = element.projectCode
            obj.projectId = element.proId
            obj.projectName = element.projectName
            obj.tempAmount = element.tempAmtStr
            obj.amount = element.reiAmtStr
            obj.amountStr = element.reiAmtStr
            obj.tempAmountStr = element.reiAmtStr
            obj.useSciType = element.useSciType
            obj.scientificType = element.sciType
            obj.mainProjectId = element.mainProId
            obj.balance = element.balance
            this.tableDataProject.push(obj)
          })
          // 获取支付信息
          this.dynamicValidateForm.domains = []
          response.data.reiFormPayInfoList.forEach(element => {
            if (element.payType === 1) { // 现金支付
              const reiFormPayInfo = {}
              reiFormPayInfo.accName = element.accName
              reiFormPayInfo.payAmt = element.payAmt
              reiFormPayInfo.remark = ''
              reiFormPayInfo.payAmtStr = element.payAmtStr
              reiFormPayInfo.payType = element.payType
              reiFormPayInfo.payway = '【现金/支票】'
              reiFormPayInfo.value = '支付方式：【现金/支票】   领款人：' + reiFormPayInfo.accName + '    领款金额：' + reiFormPayInfo.payAmtStr
              reiFormPayInfo.printDetail = '领款人：' + reiFormPayInfo.accName
              reiFormPayInfo.printDetailTd = '支付方式：【现金/支票】<br>' + '总金额：' + reiFormPayInfo.payAmtStr + '元<br>' + '附言：' + (reiFormPayInfo.remark ? reiFormPayInfo.remark : '')
              this.dynamicValidateForm.domains.push(reiFormPayInfo)
              // console.log(this.dynamicValidateForm.domains)
            } else if (element.payType === 2) { // 银行转账
              const reiFormPayInfo = {}
              reiFormPayInfo.accNo = element.accNo
              reiFormPayInfo.accName = element.accName
              reiFormPayInfo.bank = element.bank
              reiFormPayInfo.openBank = element.openBank
              reiFormPayInfo.payAmtStr = element.payAmtStr
              reiFormPayInfo.payAmt = element.payAmt
              reiFormPayInfo.remark = element.remark
              reiFormPayInfo.payType = element.payType
              // 是否是对公账号
              reiFormPayInfo.pubSign = element.pubSignFlag === true ? 1 : 0
              reiFormPayInfo.accType = element.accType
              reiFormPayInfo.bankNo = element.bankNo
              reiFormPayInfo.payeeAddr = element.payeeAddr
              reiFormPayInfo.payway = '【银行转账】'
              reiFormPayInfo.value = '支付方式：【银行转账】   银行账号：' + reiFormPayInfo.accNo + ' 户名：' + reiFormPayInfo.accName + '银行名称：' + reiFormPayInfo.bank + '开户行名称：' + reiFormPayInfo.openBank + '转账金额：' + reiFormPayInfo.payAmtStr + '附言：' + (reiFormPayInfo.remark ? reiFormPayInfo.remark : '')
              reiFormPayInfo.printDetail = '收款银行：' + reiFormPayInfo.bank + ',' + '收款人：' + reiFormPayInfo.accName + ',' + '收款人账号：' + reiFormPayInfo.accNo + '。'
              reiFormPayInfo.printDetailTd = '支付方式：【银行转账】<br>' + '总金额：' + reiFormPayInfo.payAmtStr + '元<br>' + '附言：' + (reiFormPayInfo.remark ? reiFormPayInfo.remark : '')
              this.dynamicValidateForm.domains.push(reiFormPayInfo)
            } else if (element.payType === 3) { // 校内转账
              const reiFormPayInfo = {}
              reiFormPayInfo.accNo = element.accNo
              reiFormPayInfo.remark = element.remark
              reiFormPayInfo.payAmt = element.payAmt
              reiFormPayInfo.payAmtStr = element.payAmtStr
              reiFormPayInfo.payType = element.payType
              reiFormPayInfo.payway = '【校内转账】'
              reiFormPayInfo.value = '支付方式：【校内转账】   校内账号：' + reiFormPayInfo.accNo + '    领款金额：' + reiFormPayInfo.payAmtStr + '附言:' + (reiFormPayInfo.remark ? reiFormPayInfo.remark : '')
              reiFormPayInfo.printDetail = '校内收款人账号：' + reiFormPayInfo.accNo
              reiFormPayInfo.printDetailTd = '支付方式：【校内转账】<br>' + '总金额：' + reiFormPayInfo.payAmtStr + '元<br>' + '附言：' + (reiFormPayInfo.remark ? reiFormPayInfo.remark : '')
              this.dynamicValidateForm.domains.push(reiFormPayInfo)
            } else if (element.payType === 4) {
              const reiFormPayInfo = {}
              reiFormPayInfo.remark = ''
              reiFormPayInfo.hedgeNo = element.hedgeNo
              reiFormPayInfo.payType = element.payType
              reiFormPayInfo.payAmtStr = element.payAmtStr
              reiFormPayInfo.payway = '【冲借款】'
              reiFormPayInfo.value = '支付方式：【冲借款】   对冲号：' + reiFormPayInfo.hedgeNo + '    冲销金额：' + reiFormPayInfo.payAmtStr
              reiFormPayInfo.printDetail = '对冲号：' + reiFormPayInfo.hedgeNo
              reiFormPayInfo.printDetailTd = '支付方式：【冲借款】<br>' + '总金额：' + reiFormPayInfo.payAmtStr + '元<br>' + '附言：' + (reiFormPayInfo.remark ? reiFormPayInfo.remark : '')
              this.dynamicValidateForm.domains.push(reiFormPayInfo)
            }
          })
          // this.getScrollTop()
          // document.getElementById("color").scrollIntoView();
          // if(this.param.signImgUrl != '' && this.param.signImgUrl != undefined){
          // document.getElementById("reimdetails").scrollIntoView(false);
          // }
        })
        // }, 1000);
        getTasks(this.page, this.limit, this.param.id).then((response) => {
          this.taskList = response.data.records
          for (var i = 0; i < this.taskList.length; i++) {
            this.taskList[i].finishtime = moment(this.taskList[i].finishtime).format('YYYY/MM/DD HH:mm:ss')
            this.taskList[i].ReimProces = '收起'
            this.Process.push(i)
          }
        })
        // 获取当前环节名称并且设置操作人
        getCurrentStepName(this.param.id).then(response => {
          if (response.data != null) {
            this.currentStepName = response.data.stepModelName
            // console.log(this.currentStepName)
          }
        })
        var msg = JSON.parse(localStorage.getItem('UserMsg'))
        this.auditData.opUserName = msg.name
      }
    },
    // 审批退回
    backForm() {
      // console.log(this.param.opinion)
      if (this.param.opinion === '' || this.param.opinion === undefined) {
        Dialog.alert({
          title: '提示',
          message: '请输入审批意见'
        }).then(() => {
          return false
        })
      } else {
        Dialog.confirm({
          title: '提示',
          message: '确认退回审批吗'
        }).then(() => {
          // on confirm
          const tempData = {}
          tempData.id = this.param.id
          tempData.reason = this.auditOpinion
          backForm(tempData).then(response => {
            if (response.messageCode === '0') {
              Dialog.alert({
                title: '审批退回',
                message: '退回成功'
              }).then(() => {})
              this.$router.push({
                path: '/todo'
              })
            }
          })
        }).catch(() => {
          // on cancel
        })
      }
    },
    // 审批提交
    reviewForm() {
      if (this.param.opinion === '' || this.param.opinion === undefined) {
        Dialog.alert({
          title: '提示',
          message: '请输入审批意见'
        }).then(() => {
          return false
        })
      } else if (this.param.opinion.length > 100) {
        Dialog.alert({
          title: '提示',
          message: '审批意见超过100个字符限制!'
        }).then(() => {
          return false
        })
      } else if (this.currentStepName === '审单' || this.currentStepName === '制单' || this.currentStepName === '出纳付款') {
        Dialog.alert({
          title: '提示',
          message: '请先进行电子签名再提交审批'
        }).then(() => {
          return false
        })
      } else {
        this.auditData.operationName = '通过'
        this.auditData.reiTaskId = this.list.taskId
        reviewForm(this.auditData.reiTaskId, this.param.opinion, this.auditData.operationName, this.param.id, this.param.signImgUrl).then(response => {
          if (response.messageCode === '0') {
            this.dis = true
            Dialog.alert({
              title: '提示',
              message: '审批成功'
            }).then(() => {})
            this.$router.push({
              path: '/todo'
            })
          }
        })
      }
    }
  }
}
</script>
<style scoped lang="scss">
	#reimdetails {
		.usercenter {
			background-image: url("../../assets/images/title_bg.jpg");
			background-size: 100% 100%;
			background-repeat: no-repeat;
		}
	}

	#reimdetails .van-field__left-icon {
		color: white;
	}

	#reimdetails .van-search__content--round {
		background-color: rgba(0, 0, 0, 0.2);
	}

	#reimdetails .van-field__control {
		color: white;
	}

	#reimdetails .van-nav-bar .van-icon {
		color: white;
	}

	#reimdetails .van-nav-bar__title {
		color: white;
		font-size: 18px;
	}

	#reimdetails .van-hairline--bottom::after {
		border-bottom-width: 0;
	}

	.boxstyle {
		margin-bottom: 1rem;
		background-color: #ffffff;
		border: 1px white solid;
		border-radius: 1rem;
	}

	#reimdetails {
		.van-divider {
			margin: 10px 0;
		}
		span {
			color: #000000;
			margin-left: 15px;
		}
	}

	#quickname {
		span {
			color: #1890ff;
			margin-left: 0;
		}
	}

	#textareaop {
		.van-cell {
			border: 1px #1890ff solid !important;
			border-radius: 5px !important;
			color: #1890ff !important;
			background-color: #eff6ff !important;
			margin-top: 8px !important;
			height: 150px !important;
		}
		.van-field__control {
			color: rgb(24, 144, 255) !important;
		}
		::-webkit-input-placeholder {
			color: #a1d2ff;
		}
	}

	.person {
		margin: 20px 0px;
		background-color: #ffffff;
		width: 92%;
		transform: translateX(4.4%) translateY(-0%);
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		box-shadow: 15px 0 15px -15px #dde9f3, -15px 0 15px -15px #dde9f3;
		.head {
			font-weight: bold;
			height: 20%;
			padding-top: 2px;
			font-size: 16px;
			vertical-align: middle;
			float:left
		}
		.body {
			height: 80%;
			padding: 0px 8px 10px 16px;
			line-height: 22px;
			font-size: 14px;
			color: #A2A2A2
		}
	}

	.persondetail {
		margin: 20px 0px;
		background-color: #ffffff;
		width: 92%;
		border-radius: 0px;
		transform: translateX(4.4%) translateY(-0%);
		box-shadow: 15px 0 15px -15px #dde9f3, -15px 0 15px -15px #dde9f3;
		.linestyle {
			border-left: 4px #1989fa solid;
			padding-left: 5px;
		}
		.headdetail {
			font-weight: 500;
			height: 20%;
			padding-top: 10px;
			padding-left: 16px;
			font-size: 16px;
		}
		.bodydetail {
			height: 80%;
			padding: 0px 8px 10px 16px;
			line-height: 25px;
			font-size: 14px;
			color: #A2A2A2
		}
		.van-collapse-item__content {
			padding: 0px !important;
		}
		.van-cell {
			padding: 10px 16px 0px 16px !important;
			;
		}
	}

	.personpeople {
		margin: 20px 0px;
		background-color: #ffffff;
		width: 92%;
		border-radius: 0px;
		transform: translateX(4.4%) translateY(-0%);
		box-shadow: 15px 0 15px -15px #dde9f3, -15px 0 15px -15px #dde9f3;
		.linestyle {
			border-left: 4px #1989fa solid;
			padding-left: 5px;
		}
		.headpeople {
			font-weight: 500;
			height: 20%;
			padding-top: 10px;
			padding-left: 16px;
			font-size: 16px;
		}
		.bodypeople {
			height: 80%;
			padding: 0px 8px 10px 16px;
			line-height: 25px;
			font-size: 14px;
			color: #A2A2A2
		}
	}

	.personindex {
		margin: 20px 0px;
		background-color: #ffffff;
		width: 92%;
		transform: translateX(4.4%) translateY(-0%);
		border-bottom-left-radius: 10px;
		border-bottom-right-radius: 10px;
		box-shadow: 15px 0 15px -15px #dde9f3, -15px 0 15px -15px #dde9f3;
		.linestyle {
			border-left: 4px #1989fa solid;
			padding-left: 5px;
			line-height: 16px;
		}
		.headindex {
			font-weight: 500;
			height: 20%;
			padding-top: 10px;
			padding-left: 16px;
			font-size: 16px;
		}
		.bodyindex {
			height: 80%;
			padding: 0px 8px 10px 16px;
			line-height: 25px;
			font-size: 14px;
			color: #A2A2A2
		}
	}

	.button {
		border-radius: 30px;
		width: 40%;
		height: 40px;
		color: #1890ff;
		margin-right: 15px;
		background-color: #eff6ff;
		border: 2px #1890ff solid;
		span {
			margin-left: 0px !important;
			color: #1890ff !important;
		}
	}

	.buttonsub {
		border-radius: 30px;
		width: 40%;
		height: 40px;
		color: #ffffff;
		margin-right: 15px;
		span {
			margin-left: 0px !important;
		}
	}

	#color {
		span {
			color: #ffffff
		}
	}

	#opinion .van-field__label {
		-webkit-box-flex: 0;
		-webkit-flex: none;
		flex: none;
		width: 75px !important;
	}

	#opinion .van-cell__value {
		position: relative;
		overflow: hidden;
		color: #969799;
		text-align: left;
		vertical-align: middle;
		word-wrap: break-word;
		border: 1px #cccccc solid !important;
		border-radius: 4px !important;
	}

	.warnrules {
		color: #FFBD0A;
		background-color: #FFF8E6;
		border: 1px solid #FFF1CC;
		font-size: 14px;
		padding: 5px 10px;
		border-radius: 5px;
		line-height: 18px;
	}
	#collapses .van-cell__right-icon {
    line-height:32px;
	}
</style>
